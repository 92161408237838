<template>
    <div>
        <div v-if="loading" class="absolute top-0 left-0 z-10 w-full h-full bg-white opacity-90">loading ...</div>
        <LayoutNav :routes="routes" :isOpen="isOpen" :toggleMenu="toggleMenu"
            :imageUrl="`https://eu.ui-avatars.com/api/?name=${result?.me?.name}`" :altText="result?.me?.name ?? ''"
            @logout="logout" />
        <div class="relative md:ml-64">
            <nav
                class="absolute top-0 left-0 z-10 items-center hidden w-full p-4 bg-transparent bg-white md:shadow-md md:flex-row md:flex-nowrap md:justify-start md:flex print:hidden">
                <div class="flex flex-wrap items-center justify-end w-full mx-auto md:flex-nowrap md:px-10 ">
                    <UInput icon="i-heroicons-magnifying-glass-20-solid" size="lg" color="white" :trailing="false"
                        class="mr-10" placeholder="Search..." v-model="search" @submit.prevent="searchSubmit"
                        @keyup.enter="searchSubmit" />
                    <Menu v-if="!loading" as="div" class="relative inline-block text-left">
                        <div>
                            <MenuButton>
                                <USkeleton v-if="loading" class="h-12 w-12" :ui="{ rounded: 'rounded-full' }" />
                                <CircleAvatar v-if="!loading"
                                    :imageUrl="`https://eu.ui-avatars.com/api/?name=${result?.me?.name!}`"
                                    :altText="result?.me?.name ?? ''" />
                            </MenuButton>
                        </div>
                        <transition enter-active-class="transition duration-100 ease-out"
                            enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
                            leave-active-class="transition duration-75 ease-in"
                            leave-from-class="transform scale-100 opacity-100"
                            leave-to-class="transform scale-95 opacity-0">
                            <MenuItems
                                class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                <div class="px-1 py-1">
                                    <MenuItem v-for="link in links" :key="link.label" v-slot="{ active }" as="NuxtLink"
                                        @click="link.action">
                                    <NuxtLink :to="link.href" :class="[
                                        active ? 'bg-blue-500 text-white' : 'text-gray-900',
                                        'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                    ]">
                                        <Icon :name="link.icon" :active="active" class="mr-2 h-5 w-5" aria-hidden="true" />
                                        {{ link.label }}
                                    </NuxtLink>
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
            </nav>
            <main class="h-screen px-5 pt-10 md:pt-28 md:px-8 bg-gray-50">
                <slot></slot>
            </main>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import CircleAvatar from '~/components/mics/CircleAvatar.vue';
import { useMeQuery } from '~/graphql/types';
import { navRoutes as routes } from '~/routes';
const { result, error, loading } = useMeQuery({
    fetchPolicy: 'cache-and-network',
});
const { onLogout, getToken } = useApollo()
const { $swal } = useNuxtApp();

interface LinkInterface {
    action?: () => void;
    href: string | null;
    label: string;
    icon: string;
}

onMounted(async () => {
    if (!getToken() || error.value != null) {
        await onLogout();
        await navigateTo('/auth/login');
    }
});

const isOpen = ref(false)
const toggleMenu = (value: boolean) => {
    isOpen.value = value
}

const search = ref<string>('')

const searchSubmit = () => {
    if (search.value) {
        navigateTo(`/admin/search?q=${search.value}`, { external: true })
    }
}

const logout = async () => {
    ($swal as any).fire({
        title: 'แน่ใจหรือไม่?',
        text: 'คุณต้องการออกจากระบบใช่หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ออกจากระบบ',
        cancelButtonText: 'ยกเลิก',
        buttonsStyling: false,
        customClass: {
            // tailwind
            confirmButton: 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2',
            cancelButton: 'bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded',
        }
    }).then(async (result: { isConfirmed: Boolean }) => {
        if (result.isConfirmed) {
            await onLogout();
            await navigateTo('/auth/login');
        }
    })
}

const links: Array<LinkInterface> = [
    { href: '/admin/profile', label: 'จัดการโปรไฟล์', icon: 'ic:outline-settings' },
    { href: null, action: logout, label: 'ออกจากระบบ', icon: 'ic:outline-logout' },
];

</script>
<style scoped></style>