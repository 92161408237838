import type { SidebarRoute } from "~/types";
export const navRoutes: SidebarRoute[] = [
  {
    category: "ทั่วไป",
    routes: [
      {
        path: "/admin",
        name: "สถิติภาพรวม",
        icon: "ic:outline-dashboard",
      },
      {
        path: "/admin/travel",
        name: "แหล่งท่องเที่ยว",
        icon: "ic:outline-travel-explore",
      },
      {
        path: "/admin/food",
        name: "อาหารท้องถิ่น",
        icon: "ic:outline-food-bank",
      },
      {
        path: "/admin/wisdom",
        name: "ภูมิปัญญาท้องถิ่น",
        icon: "ic:outline-house",
      },
      {
        path: "/admin/plant",
        name: "พืชท้องถิ่น",
        icon: "ic:outline-eco",
      },
    ],
  },
  {
    category: "จัดการข้อมูลพื้นฐาน",
    routes: [
      {
        path: "/admin/user",
        name: "ผู้ใช้งาน",
        icon: "ic:outline-person",
        isDisabled: false,
      },
      {
        path: "/admin/profile",
        name: "ข้อมูลส่วนตัว",
        icon: "ic:outline-account-circle",
        isDisabled: false,
      },
    ],
  },
  {
    category: "เกี่ยวกับระบบ",
    routes: [
      {
        path: "https://bit.ly/3UNUi7p",
        name: "คู่มือการใช้งาน",
        icon: "ic:outline-help",
        target: "_blank",
      },
      {
        path: "/about",
        name: "เกี่ยวกับระบบ",
        icon: "ic:outline-info",
      },
    ],
  },
];
